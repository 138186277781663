/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {Fragment} from "react";
import { TitlePortalContext } from "./TitlePortalContext";
import UploadDoc from "./UploadDoc";
import UploadedDoc from "./UploadedDoc";
import FailedUploadDoc from "./FailedUploadDoc";
import {text, wrapper} from "./requiresOriginalStyle";
import AddTracking from "./AddTracking";
import {Add} from "@material-ui/icons";


export default function MissingDoc({
  data,
  docInfo: { docType, requiresOriginal, digitalReceived, disabled },
}) {
  const {
    uploadModalState,
    uploadMissingDocState,
    uploadedDocsState,
    failedUploadDocsState,
  } = React.useContext(TitlePortalContext);
  const [uploadModal, setUploadModal] = uploadModalState;
  const [uploadMissingDoc, setUploadMissingDoc] = uploadMissingDocState;
  const [uploadedDocs, setUploadedDocs] = uploadedDocsState;
  const [failedUploadDocs, setFailedUploadDocs] = failedUploadDocsState;

  const showRequiresOriginal =
    requiresOriginal &&
    !(uploadModal === data.loanId && uploadMissingDoc === docType);
  const uploaded = uploadedDocs.some(
    (d) => d === data.loanId + docType
  );
  const failedUpload = failedUploadDocs.some(
    (d) => d === data.loanId + docType
  );

  const renderComponent = () => {
    if (uploaded) return <UploadedDoc docType={docType} />;
    if (failedUpload)
      return <FailedUploadDoc data={data} requiresOriginal={requiresOriginal} docType={docType} />;
    return <UploadDoc data={data} requiresOriginal={requiresOriginal} digitalReceived={digitalReceived} disabled={disabled} docType={docType} />;
  };
  
  return (
    <Fragment>
      {renderComponent()}
      {showRequiresOriginal &&
          <div css={wrapper}>
            <span css={text}>Original Required</span>
          </div>
      }
    </Fragment>
  );
}
