/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, withStyles, Tooltip } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import OutsideClickHandler from "react-outside-click-handler";
import Icon, { IconType } from "../../components/ui/icons/Icon";
import RequiresOriginalModal from "./RequiresOriginalModal";
import { TitlePortalContext } from "./TitlePortalContext";
import {
  modalWrapper,
  modalText,
  buttonText,
  dropzoneContent,
  dropzoneStyles,
  dropzoneText,
  uploadingDocText,
  useStyles,
  CustomTooltip,
} from "./uploadDocStyle";
import { submitDocument } from "./TitleCoDocumentUploadQueue";
import LinearProgress from "@material-ui/core/LinearProgress";

export function UploadModal({
  data,
  generalUpload,
  missingDoc,
  requiresOriginal,
  digitalReceived,
  uploadFile,
}) {
  const [file, setFile] = useState();
  const [progress, setProgress] = React.useState(0);
  const [submitStatus, setSubmitStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  const {
    uploadedDocsState,
    uploadModalState,
    uploadInProgressState,
    uploadMissingDocState,
    failedUploadDocsState,
    token,
  } = React.useContext(TitlePortalContext);
  const [uploadedDocs, setUploadedDocs] = uploadedDocsState;
  const [uploadModal, setUploadModal] = uploadModalState;
  const [uploadInProgress, setUploadInProgress] = uploadInProgressState;
  const [uploadMissingDoc, setUploadMissingDoc] = uploadMissingDocState;
  const [failedUploadDocs, setFailedUploadDocs] = failedUploadDocsState;

  const classes = useStyles();
  const submit = async () => {
    setSubmitStatus({ ...submitStatus, isLoading: true });
    setUploadInProgress(true);

    try {
      if (!file) throw new Error("No File Uploaded");
      await submitDocument(token, data.loanId, missingDoc, data.clientId, file);
      setSubmitStatus({
        ...submitStatus,
        isLoading: false,
        isSuccess: true,
      });
      setUploadInProgress(false);
    } catch (error) {
      setSubmitStatus({
        ...submitStatus,
        isLoading: false,
        isError: true,
      });
      setUploadInProgress(false);
    }
  };

  const StyledLinearProgress = withStyles({
    barColorPrimary: {
      backgroundImage:
        "linear-gradient(268deg, #419a8e 98%, #3f8b7d 51%, #256053 3%)",
      width: 140,
      height: 8,
      borderRadius: 4,
    },
  })(LinearProgress);

  const closeModalFunc = () => setUploadModal(false);

  const onSubmitSuccess = () => {
    const newDoc = uploadModal + uploadMissingDoc;

    setUploadedDocs((docs) => docs.add(newDoc));
    setFailedUploadDocs(failedUploadDocs.filter((doc) => doc !== newDoc));
  };

  const onSubmitError = () => {
    setFailedUploadDocs((docs) => docs.add(uploadModal + uploadMissingDoc));
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      uploadFile && uploadFile(acceptedFiles[0]);
    },
    [setFile, uploadFile]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: ".pdf",
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (generalUpload) {
      return;
    }
    if (submitStatus.isSuccess) {
      setProgress(100);
      onSubmitSuccess();
      closeModalFunc();
    } else if (submitStatus.isError) {
      onSubmitError();
      closeModalFunc();
    } else if (submitStatus.isLoading) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 80 ? 80 : prevProgress + 20
        );
      }, 400);
      return () => {
        clearInterval(timer);
      };
    } else if (file && !submitStatus.isLoading && !submitStatus.isError) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, closeModalFunc, submitStatus, generalUpload]);

  return uploadInProgress ? (
    <div>
      <span css={uploadingDocText}>{missingDoc === 'Municipal Lien Certificate'
          ? 'Tax Cert/Municipal Lien Certificate'
          : missingDoc} Uploading </span>
      <StyledLinearProgress
        className={classes.ProgressBar}
        variant="determinate"
        value={progress}
      />
    </div>
  ) : (
    <OutsideClickHandler onOutsideClick={() => setUploadModal(false)}>
      <div {...getRootProps({ css: !generalUpload && modalWrapper })}>
        {requiresOriginal ? (
          <>
            <input {...getInputProps({ disabled: !!file })} />
            <RequiresOriginalModal data={data} isNote={false} />
            <CustomTooltip
              arrow
              title={digitalReceived ? "Digital already received" : ""}
            >
              <span
                css={{
                  width: "91px",
                  height: "32px",
                }}
              >
                <Button
                  variant="outlined"
                  className={classes.UploadButton}
                  onClick={open}
                  disabled={digitalReceived}
                >
                  <span css={buttonText}>Upload File</span>
                </Button>
              </span>
            </CustomTooltip>
          </>
        ) : (
          <>
            {!generalUpload && <div css={modalText}>{missingDoc === 'Municipal Lien Certificate'
                ? 'Tax Cert/Municipal Lien Certificate'
                : missingDoc}</div>}
            <div css={[dropzoneStyles, { height: generalUpload ? 215 : 135 }]}>
              <div css={dropzoneContent}>
                <input {...getInputProps({ disabled: !!file })} />
                <>
                  <p css={dropzoneText}>Drag File to Upload</p>
                  <div>
                    <Icon
                      icon={
                        generalUpload
                          ? IconType.ModalUploadFileIconBig
                          : IconType.ModalUploadFileIcon
                      }
                    />
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      className={classes.UploadButton}
                      onClick={open}
                    >
                      <span css={buttonText}>Upload File</span>
                    </Button>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
}
